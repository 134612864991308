import * as React from "react"
import { Stack } from "/src/components/grid"
import { Button } from "/src/components/button"
import { H1 } from "/src/components/text"

import * as Text from "/src/components/text"

import { navigate } from "gatsby"

import Header from "/src/components/header"

const Container = ({ children }) => {

    return (
        <div style={{
            maxWidth: 480,
            margin: `0 auto`,
            padding: `0 1.0875rem`,
        }}>
            {children}
        </div>
    )
}



const Results = () => {


    return (
        <div>
            <Header />
            <div style={{ paddingTop: 144 }}></div>
            <Container>
                <Stack gap={24} align="center">
                    <H1 center>Маленькі крафтові виробники — супергерої війни</H1>
                    <Text.S center>

                        <p>
                            Ви підтримуєте українську економіку. Ви постачаєте товари першої необхідності разом з маленькими приємностями українцям. Ви закохуєте іноземців в Україну та її культуру.
                        </p>
                        <p>
                            Ми хочемо підтримати усіх, хто має сили розвивати бізнес навіть під час війни. 100 малих крафтових виробників отримають по 1000 доларів, щоб продовжувати працювати.
                        </p>
                    </Text.S>

                    <Button type="submit" fluid onClick={() => navigate(`/application-form`)}>Розпочнімо</Button>
                    <div>
                        <Text.S center light>
                            <p>Заповніть коротку заявку: це займе не більш як пів години. Відповідайте на запитання українською та будьте щирими.</p>

                            <p>На результати чекайте вже 31 травня.</p>
                        </Text.S>

                    </div>
                </Stack>
            </Container>
        </div>
    )
}

export default Results